import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Grid } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';

import { SpinnerComponent } from 'components/common';
import { BaseInput, CheckboxWrapper, CustomButton, CustomCheckbox } from 'components/shared';

import { BROADCAST_MESSAGE, FETCH_ALL_EMPLOYEES } from '../../../query';
import { MultiSelectCreatableDropdown } from '../../shared/BaseDropdown';

import { MessageCardComponent } from './MessageCard';

const testEmployeeOption = {
  label: 'Ammiel',
  value: '126723hdhjwd9hwhej',
  tabValue: '',
};
export const BroadCastMessage = ({ broadcastTitle, closeBroadcastModal, closeModal }) => {
  const { successToast, errorToast } = useToastify();

  const formMethods = useForm({
    defaultValues:
      process.env.NODE_ENV === 'test'
        ? { message: 'hello', employeeOption: [testEmployeeOption] }
        : {},
  });

  const [broadCast, { loading: sending }] = useMutation(BROADCAST_MESSAGE);

  const watchMessage = formMethods.watch('message');
  const watchEmployeeOption = formMethods.watch('employeeOption');

  const checkMessage = watchMessage && watchMessage.length;
  const checkEmp = watchEmployeeOption && watchEmployeeOption.length;

  const btnDisable = checkMessage && checkEmp;

  const { data, loading } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      filters: { isDefaultAdmin: false },
    },
  });
  const employees = data?.fetchUsersFiltered?.data;

  const otherMemberOptions = employees?.map((user) => ({
    label: (
      <Flex key={user?.email} alignItems="center" p="2" gap="4px" borderRadius="8px">
        <img
          width="24px"
          src={
            user.imageUrl === null
              ? `https://avatars.dicebear.com/api/adventurer/${Math.random()}.svg`
              : user.imageUrl
          }
          style={{ borderRadius: '50%' }}
          height="24px"
          alt="cat"
        />
        <span style={{ fontStyle: '14px', textTransform: 'capitalize' }}>
          {user.firstName} {user.lastName}
        </span>
      </Flex>
    ),
    value: user._id,
    tabValue: `${user.firstName} ${user.lastName}`,
  }));

  if (loading)
    return (
      <Grid minH="20rem" minW="20rem" placeContent="center">
        <SpinnerComponent data-testid="loading" />
      </Grid>
    );

  const options = [...otherMemberOptions];

  const employeeOptions = [
    {
      label: 'Search Results:',
      options,
    },
  ];

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const ids = data && data?.employeeOption?.map(({ value }) => value);

    const message = data?.message;
    try {
      const res = await broadCast({
        variables: {
          data: {
            message: message,
            userIds: ids,
          },
        },
      });
      res?.data?.broadcastMessage?.status === 200
        ? successToast(res?.data?.broadcastMessage?.message)
        : errorToast(res?.data?.broadcastMessage?.message);
    } catch (error) {
      errorToast('Error occured');
    } finally {
      formMethods.reset();
      handleCancel();
    }
  };

  const handleCancel = () => {
    closeBroadcastModal();
  };

  return (
    <>
      <Header>
        <p>Broadcast Message</p>
        <IoClose cursor={'pointer'} size="22" onClick={() => closeModal()} />
      </Header>
      <MessageCardComponent wish={broadcastTitle} data-testid="broadcast-component">
        <div>
          <p className="label">Message</p>
          <BaseInput
            type="textarea"
            {...formMethods.register('message', {
              required: { value: true, message: 'This is required.' },
            })}
          />
        </div>

        <p className="label"> Add People</p>
        <Box w="100%" marginBottom="10px" marginTop="-5px">
          <Controller
            defaultValue=""
            control={formMethods.control}
            name="employeeOption"
            render={({ field }) => (
              <MultiSelectCreatableDropdown
                {...field}
                fontSize="1.6rem"
                w={{ base: '100%', lg: '15rem' }}
                mt="1.2rem"
                style={{
                  flexWrap: 'wrap',
                  maxWidth: '400px',
                }}
                options={employeeOptions}
              />
            )}
          />
        </Box>
        <CheckboxWrapper>
          <CustomCheckbox variant="green-checkbox" label={'All Employees'} />
        </CheckboxWrapper>

        <Flex mt="48px" mb="58px" gap="4px" justifyContent="flex-end">
          <CustomButton
            onClick={handleCancel}
            type="button"
            variant="black--outline"
            h="48px"
            mW="125px"
            name="Cancel"></CustomButton>
          <CustomButton
            disabled={!btnDisable}
            onClick={formMethods.handleSubmit(onSubmit)}
            type="submit"
            variant="primary"
            mW="125px"
            name="Share"
            loading={sending}
            h="48px"></CustomButton>
        </Flex>
      </MessageCardComponent>
    </>
  );
};

const Header = styled(Flex)`
  justify-content: space-between;
  padding: 3.4rem 2.4rem 0;

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }
`;
