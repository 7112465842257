import React from 'react';
import { Box, Grid, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { format, intlFormatDistance } from 'date-fns';

export function ChatDetail(props) {
  const name = getChatName(props.from);
  const latestMessage = props?.latest_message;
  const message = latestMessage?.text;
  const time = latestMessage?.timestamp ? new Date(latestMessage?.timestamp) : null;
  const imageUrl = !props.from?.imageUrl
    ? `https://avatars.dicebear.com/api/initials/${name}.svg`
    : props.from?.imageUrl;
  return (
    <ChatDetailWrapper
      p="16px"
      pt="6px"
      border="1px solid var(--grey-150)"
      borderRadius="8px 0 0 8px"
      bg="white"
      className={props.active ? 'active' : ''}
      onClick={props.onSelect}
      title={`${name}: ${message}`}>
      {time ? <Time time={time} key={props._id} /> : null}
      <Grid gap="12px" templateColumns={'32px auto 2ch'}>
        <Box className="image">
          <Image
            src={imageUrl}
            w="32px"
            h="32px"
            objectFit="cover"
            objectPosition="center"
            borderRadius="50%"
          />
        </Box>
        <Grid className="content" autoRows="max-content" gap="8px">
          <Box fontWeight="600" className="line-clamp-1" lineHeight="1.9rem">
            {name}
          </Box>
          <Box
            className="line-clamp-1"
            fontSize="1.4rem"
            lineHeight="1.65rem"
            color="brand.grey.text"
            maxH="3.2rem">
            {message}
          </Box>
        </Grid>
        {props.unread ? (
          <Grid className="status" alignItems="flex-end">
            <Box
              bg="brand.primary"
              color="white"
              fontSize="1.2rem"
              fontWeight="600"
              display="grid"
              placeContent={'center'}
              w="2rem"
              h="2rem"
              borderRadius="50%">
              {props.unread}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </ChatDetailWrapper>
  );
}

let interval;
export function Time(props) {
  const [time, setTime] = React.useState(() => {
    return props.forMessage ? showAsTime(props.time) : intlFormat(props.time);
  });

  function intlFormat(d) {
    return intlFormatDistance(d, new Date());
  }

  function showAsTime(d) {
    return format(d, 'hh:mm a');
  }

  if (!props.forMessage) {
    React.useEffect(() => {
      interval = setInterval(() => {
        const formatted = intlFormat(props.time);
        if (formatted !== time) {
          setTime(() => formatted);
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [props.time]);
  }

  return (
    <Box
      h="1.4rem"
      mb="-2px"
      className="time"
      textAlign={props.textAlign || 'right'}
      fontSize="1.2rem"
      lineHeight="1.4rem"
      color="brand.grey.500">
      {time}
    </Box>
  );
}

export const getChatName = (employee) =>
  employee?.firstName ? `${employee.firstName} ${employee.lastName}` : employee;

const ChatDetailWrapper = styled(Grid)`
  box-shadow: 0 4px 20px rgba(228, 228, 228, 0.25);
  cursor: pointer;

  &:hover {
    background: #f8faff;
  }

  &.active {
    border: 1px solid var(--green);
    background: var(--green-light);
  }
`;
