import { gql } from '@apollo/client';

export const FETCH_DASHBOARD_SUMMARY = gql`
  query FetchDashboardSummary {
    fetchDashboardSummary {
      data {
        employees {
          department
          members {
            avatar
            fullName
          }
        }
        departments
        designations
        newEmployees
        totalEmployees
        leaves
        events {
          birthdays {
            _id
            imageUrl
            firstName
            lastName
            dob
            email
          }
          anniversaries {
            imageUrl
            firstName
            lastName
            dob
            email
          }
        }
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendBirthdayWish($data: BirthdayInputData!) {
    sendBirthdayWish(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const BROADCAST_MESSAGE = gql`
  mutation broadcastMessage($data: BroadcastInputData!) {
    broadcastMessage(data: $data) {
      status
      message
      data {
        _id
      }
    }
  }
`;

export const FETCH_PIPELINE_DATA = gql`
  query fetchPipelineData {
    fetchPipelineData {
      status
      message
      data {
        applicantCount
        _id
        rank_1
        rank_2
        rank_3
        rank_4
        rank_5
      }
    }
  }
`;
