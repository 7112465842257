/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Grid, Image } from '@chakra-ui/react';
import { format, isThisYear, isToday, isYesterday } from 'date-fns';
import { getItem } from 'utils';

import { SpinnerComponent } from '../../../components';

import { getChatName, Time } from './ChatDetail';

export function ChatMessageList(props) {
  const { messages } = props;
  const [observerKey, setObserverKey] = React.useState(false);

  const messageList = messages?.map((message, index) => (
    <ChatMessageDetail
      key={message._id}
      {...message}
      prevMessage={index > 0 ? messages[index - 1] : null}
      first={props.first}
    />
  ));

  return (
    <Grid
      py="24px"
      autoFlow={'row'}
      autoRows="max-content"
      gap="12px"
      maxW="800px"
      w="100%"
      mx="auto"
      h="100%"
      overflowY={'auto'}
      className={'message-list-root'}>
      <InfiniteLoader {...props} key={observerKey} setObserverKey={setObserverKey} />
      {messageList}
      <Box h={'1rem'}></Box>
    </Grid>
  );
}

function InfiniteLoader(props) {
  const el = React.useRef();
  const observer = React.useMemo(() => {
    const root = document.querySelector('.message-list-root');
    return new IntersectionObserver(onShowOnScreen, {
      root,
      threshold: 0,
      rootMargin: `${window.innerHeight}px`,
    });
  }, [el.current]);

  function onShowOnScreen(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && props.data?.fetchMessages?.hasNextPage && !props.loading) {
        props.setPage((p) => p + 1);
        props.setFirst(false);
      }
    });
  }

  React.useEffect(() => {
    if (el.current) observer.observe(el.current);
    return () => (el.current ? observer?.unobserve(el.current) : null);
  }, [el.current]);

  if (props.loading) return <SpinnerComponent h="10rem" />;
  return <Box ref={el} h={'0'}></Box>;
}

function ChatMessageDetail(props) {
  const authorName = getChatName(props?.author);
  const userDetails = getItem('hrm_user');
  const isMe = props?.author?._id === userDetails?._id;
  const content = React.useRef();
  const time = new Date(props.timestamp);
  const imageUrl = !props?.author?.imageUrl
    ? `https://avatars.dicebear.com/api/initials/${authorName}.svg`
    : props?.author?.imageUrl;
  const root = React.useRef();
  const dayLabel = getDay(time);

  function getDay(t) {
    if (isToday(t)) {
      return 'Today';
    } else if (isYesterday(time)) {
      return 'Yesterday';
    } else {
      return format(time, `iii, dd MMM ${!isThisYear(t) ? 'YYY' : ''}`);
    }
  }

  React.useEffect(() => {
    if (root.current && root.current?.scrollIntoView && props.first) root.current?.scrollIntoView();
    if (content.current)
      content.current.innerHTML = content.current?.innerHTML.replace(/\r\n|\r|\n/g, '<br />');
  }, []);

  const isSameAuthor = props.prevMessage && getChatName(props.prevMessage.author) === authorName;
  const isSameLabel =
    props.prevMessage && dayLabel === getDay(new Date(props.prevMessage.timestamp));
  const authorImage = !isSameAuthor ? (
    <Image
      w={'32px'}
      h={'32px'}
      mt={'4px'}
      borderRadius={'50%'}
      src={imageUrl}
      alt={`${authorName} profile pic`}
      objectFit={'cover'}
      objectPosition={'center'}
    />
  ) : null;

  return (
    <>
      {!isSameLabel ? (
        <Grid
          templateColumns={'1fr'}
          mb={'10px'}
          position={'sticky'}
          top={'-10px'}
          justifyItems={'center'}>
          <Box
            p={'4px 12px'}
            bg={'var(--grey-100)'}
            border={'1px solid var(--grey-150)'}
            textAlign="center"
            borderRadius="3rem"
            w="180px">
            {dayLabel}
          </Box>
        </Grid>
      ) : null}
      <Grid
        templateColumns={isMe ? 'auto 40px' : '40px auto'}
        gap="8px"
        px="24px"
        maxW="80%"
        justifySelf={isMe ? 'flex-end' : 'flex-start'}
        className={'single-message-root'}
        ref={root}>
        <Box className="author" gridArea={isMe ? '1/2/2/3' : null}>
          {authorImage}
        </Box>
        <Box>
          <Box
            className="content"
            bg={isMe ? 'brand.primary' : '#EFF2F4'}
            color={isMe ? 'white' : 'inherit'}
            p={'10px 16px'}
            borderRadius="8px"
            mb="3px"
            overflowWrap="anywhere"
            ref={content}>
            {props.text}
          </Box>
          <Time time={time} textAlign={isMe ? null : 'left'} forMessage />
        </Box>
      </Grid>
    </>
  );
}
