import { format, parseISO } from 'date-fns';

export const createActionHandler = ({
  editAction,
  queryEmployee,
  exitEmployee,
  viewUser,
  statusChangeAction,
  deleteAction,
  exitAction,
  approveAction,
  denyAction,
  sendMessage,
}) => {
  return ({ action, id, email, status }) => {
    switch (action) {
      case 'edit':
        editAction({ id });
        break;
      case 'query':
        queryEmployee(action);
        break;
      case 'exit':
        exitEmployee(action);
        break;
      case 'send-message':
        sendMessage({ action, id, email });
        break;
      case 'delete':
        deleteAction({ id });
        break;
      case 'exit-user':
        exitAction({ action, id });
        break;
      case 'deactivate':
      case 'activate':
        statusChangeAction({ action, id, status });
        break;
      case 'view':
        viewUser({ action, id });
        break;
      case 'approve':
      case 'force-approve':
        approveAction({ action, id });
        break;
      case 'deny':
      case 'force-deny':
        denyAction({ action, id });
        break;
    }
  };
};

export const getYears = (date) => {
  // aniversary is a date
  let ageDifMs = Date.now() - new Date(date).getTime();
  let ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const formatDate = (value) => {
  if (value) {
    return format(parseISO(value), 'MMMM dd, yyyy');
  }
  return value;
};
