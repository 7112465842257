import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Grid, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FETCH_ALL_EMPLOYEES, INITIATE_CONVO } from 'query';
import { getItem } from 'utils';

import { SpinnerComponent } from 'components';

import { getFrom } from '../helpers';

import { getChatName } from './ChatDetail';

export function EmployeeList(props) {
  const searchString = props.searchString;
  const filters = {};
  const page = 1;
  const userDetails = getItem('hrm_user');
  const { data, loading } = useQuery(FETCH_ALL_EMPLOYEES, {
    variables: {
      page: page,
      filters: searchString ? { ...filters, searchString: searchString } : { ...filters },
    },
  });

  if (loading) return <SpinnerComponent h="40rem" />;
  const employeeData =
    data?.fetchUsersFiltered?.data?.filter((e) => e._id !== userDetails?._id) || [];

  const employeeList = employeeData.map((e) => <EmployeeDetail key={e._id} {...e} {...props} />);

  return <Grid autoRows="max-content">{employeeList}</Grid>;
}

function EmployeeDetail(props) {
  const name = getChatName(props);
  const imageUrl = !props.imageUrl
    ? `https://avatars.dicebear.com/api/initials/${name}.svg`
    : props.imageUrl;

  const [initConvo, { loading }] = useMutation(INITIATE_CONVO);
  const userDetails = getItem('hrm_user');

  async function handleEmployeeClick(id) {
    const res = await initConvo({ variables: { data: { receipientId: id } } });
    const chat = { ...res.data.initiateConversation.data };
    chat.from = getFrom(chat.participants, userDetails?._id);
    props.setActiveChat(chat);
    props.setActive(chat._id);
    props.setAddingEmployee(false);
  }

  return (
    <EmployeeDetailWrapper
      data-testid={'employee-card'}
      p="16px"
      py="10px"
      bg="white"
      border="1px solid var(--grey-150)"
      borderRadius="8px 0 0 8px"
      onClick={() => handleEmployeeClick(props._id)}>
      <Box>
        <Image
          src={imageUrl}
          w="32px"
          h="32px"
          objectFit="cover"
          objectPosition="center"
          borderRadius="50%"
        />
      </Box>
      <Box>{name}</Box>
      <Box>{loading ? <BiLoaderAlt className="spin" /> : null}</Box>
    </EmployeeDetailWrapper>
  );
}

const EmployeeDetailWrapper = styled(Box)`
  display: grid;
  grid: 1fr / 32px auto max-content;
  gap: 8px;
  align-items: center;
  box-shadow: 0px 4px 20px rgba(228, 228, 228, 0.25);
  cursor: pointer;

  &:hover {
    background: #f8faff;
  }

  &.active {
    border: 1px solid var(--green);
    background: var(--green-light);
  }
`;
