import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { INDIVIDUAL_TRAINING_REPORT, SHARED_TRAINING_REPORT } from 'query';

import { TableComponent } from 'components';
import { CustomButton, ModalBackButton } from 'components';
import { Status } from 'components/tables/TableConfig';

import arrowDown from '../../../../assets/images/arrowdown.svg';

import { ReportTableColumns } from './data';
import { exportCsv, getCsv, modifyLearningCsv } from './helpers';

const ReportUserImage = (data) => {
  let nums = data?.length;
  if (data?.length > 0) {
    return (
      <Enrolled>
        <Flex className="image__wrapper">
          {data?.map((el) => {
            return (
              <img
                key={`${el.firstName}${el.lastName}`}
                src={
                  el?.imageUrl ||
                  `https://avatars.dicebear.com/api/initials/${el?.firstName}-${el?.lastName}.svg?backgroundColor=gray`
                }
                alt="image"
              />
            );
          })}
          <Text marginLeft="12px">+{nums - 1}</Text>
        </Flex>
      </Enrolled>
    );
  } else {
    return <Text>N/A</Text>;
  }
};

export function TrainingReports() {
  const { data: sharedTraining } = useQuery(SHARED_TRAINING_REPORT, {
    variables: {
      filter: {
        keyword: '',
        isShared: true,
      },
    },
  });
  const { data: individualTraining, loading } = useQuery(INDIVIDUAL_TRAINING_REPORT, {
    variables: {
      filter: {
        keyword: '',
      },
    },
  });

  const arrayData = sharedTraining?.fetchTraining?.data.concat(
    individualTraining?.fetchTraining?.data
  );

  function getType(data) {
    let type = 'N/A';

    if (data === true) {
      type = 'Shared';
    } else {
      type = 'Individual';
    }

    return type;
  }

  const navigate = useNavigate();

  const [selectAll, setSelectAll] = React.useState(false);
  const newReportTableData = arrayData?.map((elem) => {
    return {
      col1: elem?.name || 'N/A',
      col2: ReportUserImage(elem?.employees),
      col3: getType(elem?.isShared),
      col4: elem?.schedule?.startDate || 'N/A',
      col5: elem?.schedule?.endDate || 'N/A',
      col6: elem?.cost || 'N/A',
      col7: Status({ status: elem?.status?.replace(/_/, ' ') }) || 'N/A',
    };
  });

  return (
    <Wrapper>
      <Flex
        border={'solid 1px var(--grey-150)'}
        alignItems={'center'}
        borderRadius={'4px'}
        gap={'24px'}
        p={'24px'}
        backgroundColor={'var(--white)'}>
        <ModalBackButton
          onClick={() => navigate('/admin/reports')}
          data-testid="modal-back-button"
        />
        <Heading className="header-text">Training Reports</Heading>
      </Flex>

      <Flex justifyContent="flex-end" marginBottom="24px" marginTop="24px">
        <CustomButton
          srcRight={arrowDown}
          name={'Export'}
          mW={'122px'}
          h={'48px'}
          onClick={() => exportCsv(getCsv(modifyLearningCsv, arrayData), 'LearningReport')}
        />
      </Flex>

      <Stack overflowX={'auto'}>
        <TableComponent
          noSort
          noAction
          noCheckbox
          loading={loading}
          columns={ReportTableColumns}
          data={newReportTableData}
          ids={newReportTableData?.map((e) => e._id)}
          setAllChecked={setSelectAll}
          allChecked={selectAll}
          empty={arrayData?.length === 0}
        />
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px;
`;
const Enrolled = styled.div`
  .image__wrapper {
    img {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      box-shadow: 2px 2px 4px rgba(8, 12, 35, 0.2);
      background-color: white;
    }
  }

  .image__wrapper img:not(:first-of-type) {
    margin-left: -12px;
  }
`;
