import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button, Grid, Image, Textarea } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SEND_CHAT_MESSAGE, USER_TYPING } from 'query';
import { getItem } from 'utils';

import {
  // MessageAttachmentIcon,
  MessageSendIcon,
} from 'components/shared/Svgs';

import { getChatName } from './ChatDetail';
import { EmojiMenu } from './EmojiMenu';

export function ChatControl(props) {
  const methods = useForm({ defaultValues: { [props.active]: { message: '' } } });
  const myName = getChatName('Me');
  const userDetails = getItem('hrm_user');
  const imageUrl = !userDetails?.imageUrl
    ? `https://avatars.dicebear.com/api/initials/${getChatName(userDetails)}.svg`
    : userDetails?.imageUrl;

  const { setValue, watch } = methods;
  const message = watch(`[${props.active}].message`);

  const [sendMessage] = useMutation(SEND_CHAT_MESSAGE);
  const [userTyping] = useMutation(USER_TYPING);

  async function handleSendMessage() {
    if (!message || !message.trim()) return;
    const timestamp = new Date();
    const newMessage = {
      _id: Math.random().toString(),
      text: message,
      timestamp,
      author: userDetails,
    };
    props.setFirst(true);
    props.setMessages((prev) => [...prev, newMessage]);
    sendMessage({
      variables: {
        data: {
          roomId: props.active,
          text: message.trim(),
          timestamp,
        },
      },
    });
    props.setRefetch((a) => !a);
    setValue(`[${props.active}].message`, '');
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    } else {
      userTyping({
        variables: { data: { isTyping: true, receipientId: props.activeChat.from._id } },
      });
    }
  }

  const authorImage = (
    <Image
      alignSelf={'flex-start'}
      w={'32px'}
      h={'32px'}
      mt={'8px'}
      borderRadius={'50%'}
      src={imageUrl}
      alt={`${myName} profile pic`}
    />
  );

  return (
    <Grid
      bg="#EFF2F4"
      w="100%"
      maxW="800px"
      justifySelf="center"
      alignItems={'center'}
      borderRadius="4px"
      p="16px 24px"
      templateColumns="max-content auto max-content"
      onKeyDown={handleKeyDown}>
      <FormProvider {...methods}>
        {authorImage}
        <MessageInputContainer active={props.active} />
        <MessageControl
          active={props.active}
          setMessages={props.setMessages}
          handleSendMessage={handleSendMessage}
          setFirst={props.setFirst}
        />
      </FormProvider>
    </Grid>
  );
}

function MessageInputContainer(props) {
  const { register } = useFormContext();
  return (
    <MessageInput
      placeholder="Write your message..."
      rows="3"
      {...register(`[${props.active}].message`)}
    />
  );
}

function MessageControl(props) {
  const { setValue, watch, getValues } = useFormContext();
  const message = watch(`[${props.active}].message`);

  React.useEffect(() => {
    setValue(`[${props.active}].message`, getValues()[props.active]?.message || '');
  }, [props.active]);

  return (
    <Grid templateColumns={'repeat(3, 1fr)'} alignItems="center" gap="12px" pl="8px">
      <EmojiMenu onEmojiClick={(e) => setValue(`[${props.active}].message`, message + e.emoji)} />
      {/* <Clickable>{MessageAttachmentIcon}</Clickable> */}
      <Clickable pr="4px" onClick={props.handleSendMessage} data-testid={'send-btn'}>
        {MessageSendIcon}
      </Clickable>
    </Grid>
  );
}

const Clickable = styled(Button)`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

const MessageInput = styled(Textarea)`
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  font-size: 1.6rem;
  border: none;

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: none;
    border: none;

    &:before,
    &:after {
      display: none;
    }
  }
`;
