import React from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useCountriesStatesCities } from 'hooks';
import { useToastify } from 'hooks/useToastify';
import {
  EDIT_COMPANY_DETAILS,
  FETCH_COMPANY_DETAILS,
  UPLOAD_LOGO,
} from 'query/admin/onboarding/companyDetails';

import { CompanyDetailsForm } from './CompanyDetailsForm';
import { FieldTitle } from './InputFor';

const CompanyDetails = ({ cancel }) => {
  const [imageFile, setImageFile] = React.useState([]);
  const [details, setDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const { successToast, errorToast } = useToastify();

  const { data: companyData } = useQuery(FETCH_COMPANY_DETAILS);

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: React.useMemo(() => {
      return transformDetails(details);
    }, [details]),
  });

  React.useEffect(() => {
    const toSet = transformDetails(companyData?.fetchCompanyDetails?.data);
    const hqLocations = toSet?.locations?.find((locatn) => {
      return locatn.isHeadQuarter;
    });
    const formatedLocation = toSet?.locations?.filter((locatn) => {
      return locatn.isHeadQuarter === false;
    });
    const newD = {
      ...toSet,
      locations: formatedLocation,
      hqLocation: hqLocations,
    };
    setDetails(newD);
  }, [companyData]);

  React.useEffect(() => {
    reset(details);
  }, [companyData, details]);

  const watchCountry = useWatch({ control, name: 'country' });
  const watchState = useWatch({ control, name: 'hqLocation.state' });
  const watchCity = useWatch({ control, name: 'hqLocation.city' });

  const { states, cities, countries } = useCountriesStatesCities({
    country: watchCountry,
    state: watchState,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'locations',
  });

  const [editCompanyDetails] = useMutation(EDIT_COMPANY_DETAILS, {
    refetchQueries: () => [
      {
        query: FETCH_COMPANY_DETAILS,
      },
    ],
  });

  const [uploadCompanyLogo] = useMutation(UPLOAD_LOGO, {
    refetchQueries: () => [
      {
        query: FETCH_COMPANY_DETAILS,
      },
    ],
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const hq = [
      {
        isHeadQuarter: true,
        address: data.hqLocation.address,
        state: data.hqLocation.state.value,
        city: data.hqLocation.city.value,
        country: data.country.value,
      },
    ];

    const otherlocations = data?.locations?.map((loc) => {
      return {
        isHeadQuarter: false,
        address: loc.address,
        state: loc.state.value,
        country: data.country.value,
        city: loc.city.value,
      };
    });
    const locationsToSend = [...hq, ...otherlocations];
    const dataToSend = {
      locations: locationsToSend,
      companyName: data.companyName,
      idPrefix: data.idPrefix,
    };

    try {
      const res = await editCompanyDetails({
        variables: {
          data: dataToSend,
        },
      });
      imageFile.length
        ? await uploadCompanyLogo({
            variables: {
              file: imageFile[0],
            },
          })
        : null;
      if (res?.data?.editCompanyDetails?.status === 200) {
        const message = res?.data?.editCompanyDetails?.message;
        successToast(message);
        setLoading(false);
      } else {
        errorToast('Request could not be processed');
        setLoading(false);
      }
    } catch (error) {
      errorToast('Request could not be processed');
    } finally {
      setImageFile([]);
    }
  };

  const disableBtn = !watchCountry || !watchState || !watchCity ? true : false;

  return (
    <CompanyDetailsWrapper>
      <h1 className="header">Company Details</h1>
      <Flex mt="5rem" gap="10.5rem" data-testid="company-details">
        <FieldTitle />
        <Box w="50rem">
          <form onSubmit={handleSubmit(onSubmit)}>
            <CompanyDetailsForm
              register={register}
              control={control}
              states={states}
              cities={cities}
              countries={countries}
              imageFile={imageFile}
              append={append}
              fields={fields}
              remove={remove}
              setImageFile={setImageFile}
              cancel={cancel}
              disableBtn={disableBtn}
              loading={loading}
              logoUrl={details?.logoUrl}
            />
          </form>
        </Box>
      </Flex>
    </CompanyDetailsWrapper>
  );
};

function transformDetails(details) {
  const copy = { ...details };
  const { country } = copy;
  copy.country = { label: country, value: country };
  copy.locations = details?.locations?.map((loc) => {
    return {
      ...loc,
      state: { label: loc.state, value: loc.state },
      city: { label: loc.city, value: loc.city },
    };
  });

  return copy;
}

export { CompanyDetails };

export const CompanyDetailsWrapper = styled.div`
  .header {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -0.02em;
    color: var(--black);
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
  }
`;
