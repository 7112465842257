import React from 'react';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import { FETCH_DASHBOARD_SUMMARY } from '../../../../query';
import { EmptyState, SpinnerComponent } from '../../../common';
import { Stroke, UpcomingCardContainer, UpcomingCardText } from '../styles';

import { UpcomingEventCardItem } from './UpcomingEventCardItem';

const dropdownActions = ['Send Wishes', 'Broadcast to others'];

export const UpcomingEventsCard = ({
  setShowWishModal,
  setShowBroadcastModal,
  setEmployeeDetails,
  // setEmployeeName,
  // setEmployeeEmail,
  // setEmployeeFirstName,
}) => {
  const { data: dashboardData, loading } = useQuery(FETCH_DASHBOARD_SUMMARY);

  if (loading) return <SpinnerComponent />;

  const events = dashboardData?.fetchDashboardSummary?.data.events;
  const data = [];
  events.birthdays.forEach(({ dob, ...event }) => {
    const date = new Date(dob);
    data.push({
      eventType: 'Birthday',
      ...event,
      date,
      day: date.getDate(),
      month: format(date, 'MMM').toUpperCase(),
    });
  });
  events.anniversaries.forEach(({ dob, ...event }) => {
    const date = new Date(dob);
    data.push({
      eventType: 'Work Anniversary',
      ...event,
      date,
      day: date.getDate(),
      month: format(date, 'MMM').toUpperCase(),
    });
  });

  return (
    <UpcomingCardContainer data-testid="upcoming-card-container">
      <UpcomingCardText>
        <p className="card-heading" data-testid="upcoming-card-text">
          Events in {format(new Date(), 'MMMM')}
        </p>
      </UpcomingCardText>
      <Stroke />
      <div className="card-item-container" data-testid="upcoming-event-cardItem-container">
        {!data.length ? <EmptyState h="20rem" message="No upcoming events" /> : null}
        {data.slice(0, 4).map((event) => (
          <UpcomingEventCardItem
            dropdownActions={dropdownActions}
            setShowWishModal={setShowWishModal}
            setShowBroadcastModal={setShowBroadcastModal}
            setEmployeeDetails={setEmployeeDetails}
            key={`${event.firstName} ${event.lastName}` + event.eventType}
            user={event}
            eventType={event.eventType}
            day={event.day}
            month={event.month}
            pic={
              event.imageUrl === null
                ? `https://avatars.dicebear.com/api/initials/${event.firstName} ${event.lastName}.svg?size=5`
                : event.imageUrl
            }
          />
        ))}
      </div>
    </UpcomingCardContainer>
  );
};
